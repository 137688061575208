import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import axios from 'utils/axios';
import { VERSION_ROOT } from '../../utils/config';
// material
import { Alert, Box, Drawer, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from 'components/@material-extend';
import Label from 'components/Label';
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import { SaeiNavSection } from 'components/saei-nav';
import { IconCollapse } from './IconCollapse';
import useSaeiSidebarConfig from './SaeiSidebarConfig';
// hooks
import useLocales from 'hooks/useLocales';
// constants
import { COLLAPSE_WIDTH, DRAWER_WIDTH } from './Dashboard.constants';
// styles
import { StyledDashboardSidebarDiv } from './Dashboard.style';
// types
import useAuth from 'hooks/useAuth';
import toast from 'react-hot-toast';
import { DashboardSidebarProps } from 'types/dashbord';
import { FILE_TYPES } from 'utils/fileExtension';

// ----------------------------------------------------------------------

export default function SaeiDashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: DashboardSidebarProps) {
  const { permissions } = useAuth();
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const [version, setVersion] = useState('');
  const [associationRule, setAssociationRule] = useState('');
  const saeiSidebarConfig = useSaeiSidebarConfig();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const fetchAppVersion = async () => {
    try {
      const response = await fetch(`${VERSION_ROOT}/version.json`);
      const appVersion = await response.json();
      setVersion(appVersion?.version);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAppVersion();
    getAssociationRule();
  }, []);

  let mergedRouteAndPermission = saeiSidebarConfig.map((route) => {
    permissions.forEach((permission) => {
      if (route.moduleName === permission.moduleName) {
        route = Object.assign(route, permission);
      }
    });
    return route;
  });

  let mergedSubmoduleAndPermission = mergedRouteAndPermission?.map((route, i) => {
    route.subModules?.forEach((permission) => {
      route.items[0]?.children?.map((item) => {
        if (item.name === permission.name) {
          item = Object.assign(item, permission);
        }

        return item;
      });
    });

    return route;
  });

  const getAssociationRule = async () => {
    try {
      const response = await axios.get(
        `https://pdf-generator.ubora.app/pdf-generate/rules-of-procedure`,
        {
          responseType: 'blob'
        }
      );

      const file = new Blob([response.data], {
        type: FILE_TYPES.PDF
      });
      const fileURL = URL.createObjectURL(file);
      setAssociationRule(fileURL);
    } catch (error: any) {
      toast.error(`${error?.message}`);
    }
  };

  const handleDownloadAssociationRule = () => {
    window.open(associationRule);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-around">
          <MHidden width="lgDown">
            <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          </MHidden>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'inline-flex'
            }}
          >
            {!isCollapse && <Logo />}
            {isCollapse && (
              <Box
                component="img"
                src="/static/brand/logo_min.svg"
                alt="Logout"
                sx={{ height: 25, width: 25, ml: 2 }}
              />
            )}
          </Box>
        </Stack>
      </Stack>

      <SaeiNavSection navConfig={mergedSubmoduleAndPermission} isShown={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <>
          <Stack
            spacing={3}
            alignItems="center"
            sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
          >
            <Alert
              icon={
                <Box
                  component="img"
                  src="/static/icons/lightbulb.svg"
                  alt="Logout"
                  sx={{ height: 20, width: 20 }}
                />
              }
              sx={{ width: 230, cursor: 'pointer' }}
              severity="warning"
              onClick={handleDownloadAssociationRule}
            >
              <Typography gutterBottom variant="subtitle2" noWrap sx={{ color: 'text.secondary' }}>
                {translate('dashboardSidebar.invite')}
                <br /> {translate('dashboardSidebar.the')}{' '}
                <Box component="span" sx={{ color: 'primary.main' }}>
                  {translate('dashboardSidebar.rules')} <br />
                  {translate(`dashboardSidebar.association`)}.{' '}
                </Box>
              </Typography>{' '}
            </Alert>
            <Label color="primary">v{version}</Label>
          </Stack>
        </>
      )}
    </Scrollbar>
  );

  return (
    <StyledDashboardSidebarDiv
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRight: 0,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </StyledDashboardSidebarDiv>
  );
}
