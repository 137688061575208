let backendHost;
let versionUrl;
let uploadFileHost;
let portalHost;

const hostname = window && window.location && window.location.hostname;

// if (hostname.includes('preprod.uboraweb.com')) {
//   versionUrl = 'https://preprod.uboraweb.com';
//   backendHost = 'https://preprod.api.uboraweb.com/';
//   uploadFileHost = 'https://preprod.upload.uboraweb.com/';
//   portalHost = 'https://preprod.portail-api.uboraweb.com/';
// } else if (hostname.includes('test.uboraweb.com')) {
//   versionUrl = 'https://test.uboraweb.com';
//   backendHost = 'https://test.api.uboraweb.com/';
//   uploadFileHost = 'https://test.upload.uboraweb.com/';
//   portalHost = 'https://test.portail-api.uboraweb.com/';
// } else if (hostname.includes('dev.uboraweb.com') || hostname.includes('localhost')) {
//   versionUrl = 'https://dev.uboraweb.com';
//   backendHost = 'https://dev.api.uboraweb.com/';
//   uploadFileHost = 'https://dev.upload.uboraweb.com/';
//   portalHost = 'https://dev.portail-api.uboraweb.com/';
// } else {
//   versionUrl = 'https://ubora.app';
//   backendHost = 'https://api.ubora.app/';
//   uploadFileHost = 'https://upload.ubora.app/';
//   portalHost = 'https://portail-api.ubora.app/';
// }

if (hostname.includes('preprod.ubora.app')) {
  versionUrl = 'https://preprod.ubora.app';
  backendHost = 'https://preprod.api.ubora.app/';
  uploadFileHost = 'https://preprod.upload.ubora.app/';
  portalHost = 'https://preprod.portail-api.ubora.app/';
} else if (hostname.includes('test.ubora.app')) {
  versionUrl = 'https://test.ubora.app';
  backendHost = 'https://test.api.ubora.app/';
  uploadFileHost = 'https://test.upload.ubora.app/';
  portalHost = 'https://test.portail-api.ubora.app/';
} else if (hostname.includes('dev.ubora.app') || hostname.includes('localhost')) {
  versionUrl = 'https://dev.ubora.app';
  backendHost = 'https://dev.api.ubora.app/';
  uploadFileHost = 'https://dev.upload-file.ubora.app/';
  portalHost = 'https://dev.portail-api.ubora.app/';
} else {
  versionUrl = 'https://ubora.app';
  backendHost = 'https://api.ubora.app/';
  uploadFileHost = 'https://upload-file.ubora.app/';
  portalHost = 'https://portail-api.ubora.app/';
}

export const API_ROOT = `${backendHost}`;

export const VERSION_ROOT = `${versionUrl}`;

export const UPLOAD_FILE_API_ROOT = `${uploadFileHost}`;

export const PORTAL_API_ROOT = `${portalHost}`;
